exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-routes-v-2-404-js": () => import("./../../../src/routes/v2/404.js" /* webpackChunkName: "component---src-routes-v-2-404-js" */),
  "component---src-routes-v-2-[default-page]-js": () => import("./../../../src/routes/v2/[default-page].js" /* webpackChunkName: "component---src-routes-v-2-[default-page]-js" */),
  "component---src-routes-v-2-contacto-js": () => import("./../../../src/routes/v2/contacto.js" /* webpackChunkName: "component---src-routes-v-2-contacto-js" */),
  "component---src-routes-v-2-index-js": () => import("./../../../src/routes/v2/index.js" /* webpackChunkName: "component---src-routes-v-2-index-js" */),
  "component---src-routes-v-2-legado-js": () => import("./../../../src/routes/v2/legado.js" /* webpackChunkName: "component---src-routes-v-2-legado-js" */),
  "component---src-routes-v-2-novedades-[id]-[slug]-js": () => import("./../../../src/routes/v2/novedades/[id]/[slug].js" /* webpackChunkName: "component---src-routes-v-2-novedades-[id]-[slug]-js" */),
  "component---src-routes-v-2-novedades-index-js": () => import("./../../../src/routes/v2/novedades/index.js" /* webpackChunkName: "component---src-routes-v-2-novedades-index-js" */),
  "component---src-routes-v-2-seleccion-[name]-[id]-js": () => import("./../../../src/routes/v2/seleccion/[name]/[id].js" /* webpackChunkName: "component---src-routes-v-2-seleccion-[name]-[id]-js" */),
  "component---src-routes-v-2-seleccion-index-js": () => import("./../../../src/routes/v2/seleccion/index.js" /* webpackChunkName: "component---src-routes-v-2-seleccion-index-js" */),
  "component---src-routes-v-2-servicios-js": () => import("./../../../src/routes/v2/servicios.js" /* webpackChunkName: "component---src-routes-v-2-servicios-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

