import axios from 'axios'

// constantes
const dataInicial = {
    name:'Template',
    short_name:'template',
    base_url:'/',
    client_id:6,
    social:
    {
        instagram:'',
        facebook:'',
        twitter:'',
        linkedin:'',
        youtube:'',
    },
    investor:{},
    contact:
    {
        phone:['' , ''],
        whatsapp:{link:'',visual:''},
        mail:''
        
    },
    location:
    {
        address:"",
        region:'',
        cp:""
    },
    keys:{
        // captcha:'6Lc9VaAaAAAAANaHwR9avf7MCpVP7z019S-g_OMi', Mediahaus
        captcha:'6Lfmez4dAAAAAPRBJgBY1CDZNZIBBCks2ZaIy-M6',
        tokko:'58d249846ac03d5c49fbb0dd5a6fa9019d574541',
        // tokko:'7d5dc24fcb137714860c3c5924cf3ac62acb3b9a',
        google_tgm:'',
        google_map:'',
        mediacore: "",
    },
    seo:{
        title:'template',
        description:'',
        url:'',
        img:'',
        image_url:'',
        site_name:'',
        locale:'',
        type:'website'
    },
    modules:[ //(STEP) 1 -- Crear variable en el store de "settings" lo usamos para guardar datos de configuracion del cliente, una especie de mockdata
    ]
}

const GET_INVESTOR = 'GET_INVESTOR'
const CHANGE_API = 'CHANGE_API'
const CHANGE_MAP = 'CHANGE_MAP'
const GET_CONFIGURATIONS = 'GET_CONFIGURATIONS' //(STEP) 2 -- Creo una constante con un mensaje que va a ser el encargado de realizar el cambio de valores en el store

export default function settingsReducer(state = dataInicial, action){
    switch(action.type){
        case GET_INVESTOR : {
            return {...state,
                investor: action.payload
            }
        }
        case GET_CONFIGURATIONS : { //(STEP) 4 -- Este switch es el que toma los mensajes y dependiendo que mensaje le mandemos, asigna el valor que le pasamos a un lugar u otro del store de "settings"
            return {...state,
                modules: action.payload //Le asigno a la variables modules de este store, el valor que envie en el paso anterior.
            }
        }
        case CHANGE_API : {
            return {...state,
                keys : {...state.keys,
                tokko: action.payload
                } 
            }
        }
        case CHANGE_MAP : {
            return {...state,
                keys : {...state.keys,
                google_map: action.payload
                } 
            }
        }
        default:
            return state
    }
}

// 2e4da1b9a9b3c724ab1cbfa7c1f92ab291ba1a2b

export const GET_INVESTOR_ACTION = (env) => async (dispatch, getState) => {
    const {APISERVER,CLIENTID} = env
    try {
        const res = await axios.get(`${APISERVER}/investor/configuracion_usuarios/publica/?client=${CLIENTID}`)
        dispatch({
            type: GET_INVESTOR,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}
export const GET_CONFIGURATIONS_ACTION = () => async (dispatch,getState) => {
     // (STEP) 3 -- Creo una acción, que se encarga de la solicitud al backend, y se enviarle al "Cerebro" del settings, el mensaje (la constante "GET_CONFIGURATIONS") y el valor (respuesta de la api) para que haga lo que tiene que hacer
     // Eta acción es la que ejecutamos en los componentes donde queremos ejectuarla, todo lo demas sucede en este archivo, pero la ejecución de esta acción, es el componente (PASO 5)
    try {
        const res = await axios.get("https://staging.mediacore.app/api/core/custom-login/publica/?client=5")
        dispatch({
            type: GET_CONFIGURATIONS,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
    // 
}
export const CHANGE_APIKEY_ACTION = (api_key) => async (dispatch, getState) => {
    dispatch({
        type: CHANGE_API,
        payload: api_key
    })
}
export const CHANGE_MAPKEY_ACTION = (map_key) => async (dispatch, getState) => {
    dispatch({
        type: CHANGE_MAP,
        payload: map_key
    })
}