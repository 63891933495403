import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

//Acá deberían ir los estilos globales.
import './src/styles/fonts.css'
import './src/styles/slick.css'

//Importo un styles u otro en base a la variable de entorno.
// if (process.env.GATSBY_TEMPLATE_TYPE === 'v1') {require('./src/styles/v1/styles.scss')} 
if (process.env.GATSBY_TEMPLATE_TYPE === 'v2') {require('./src/styles/v2/styles.scss')} 

// export { default as wrapRootElement } from './src/redux/ReduxWrapper';
import wrapWithProvider  from './src/redux/ReduxWrapper'
export const wrapRootElement = wrapWithProvider
