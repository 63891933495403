import axios from "axios"
import { orderNovelties } from "../helpers/helper.novelty"

// constantes
const dataInicial = {
  novelties: [],
  novelty: {},
  grid_novelties: [],
  filtersNovelties: [],
  tags: [],
  loading: false,
  loading_grid: false,
  loading_tags: false,
  loading_novelties: true,
  loaded_novelties: false,
}

// types
const LOADING_NOVELTY = "LOADING_NOVELTY"
const LOADING_NOVELTIES = "LOADING_NOVELTIES"
const LOADED_NOVELTIES = "LOADED_NOVELTIES"
const LOADING_TAGS = "LOADING_TAGS"
const LOADING_GRID = "LOADING_GRID"
const GET_NOVELTY = "GET_NOVELTY"
const GET_NOVELTIES = "GET_NOVELTIES"

const GET_TAGS = "GET_TAGS"

const GET_GRID_NOVELTIES = "GET_GRID_NOVELTIES"
const CLEAN_NOVELTY = "CLEAN_NOVELTY"
const CLEAN_GRID_NOVELTIES = "CLEAN_GRID_NOVELTIES"
const CLEAN_FILTERS_NOVELTIES = "CLEAN_FILTERS_NOVELTIES"
const UPDATE_FILTERS = "UPDATE_FILTERS"
const GET_FAV_NOVELTY = "GET_FAV_NOVELTY"
const CLEAR_NOVELTY = "CLEAR_NOVELTY"
const DELETE_FAV_NOVELTY = "DELETE_FAV_NOVELTY"

// reducer
export default function noveltiesReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_NOVELTY:
      return { ...state, loading: true }
    case LOADING_GRID:
      return { ...state, loading_grid: true }
    case LOADING_TAGS:
      return { ...state, loading_tags: true }
    case LOADING_NOVELTIES:
      return { ...state, loading_novelties: true }
    case GET_NOVELTY:
      return { ...state, novelty: action.payload, loading: false }
    case GET_NOVELTIES:
      return {
        ...state,
        novelties: action.payload,
        loading_novelties: false,
        loaded_novelties: true,
      }
    case GET_TAGS:
      return {
        ...state,
        tags: action.payload,
        loading_tags: false,
      }
    case GET_GRID_NOVELTIES:
      return {
        ...state,
        grid_novelties: action.payload,
        loading_grid: false,
        loading_novelties: false,
      }
    case CLEAN_NOVELTY:
      return { ...state, novelty: action.payload }
    case CLEAN_GRID_NOVELTIES:
      return { ...state, grid_novelties: [] }
    case UPDATE_FILTERS:
      return { ...state, filtersNovelties: action.payload }
    case GET_FAV_NOVELTY:
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
        loading: false,
      }
    case CLEAR_NOVELTY:
      return { ...state, novelty: {} }
    case DELETE_FAV_NOVELTY:
      return { ...state, favorites: [], loading: false }
    default:
      return state
  }
}

// actions
export const getNoveltieAction = (env, id) => async (dispatch, getState) => {
  dispatch({ type: LOADING_NOVELTY })
  try {
    const res = await axios.get(
      `${env.APISERVER}/blog/novedades/${id}/get_data_detail/?client=26`
    )
    console.log(res.data)
    dispatch({
      type: GET_NOVELTY,
      payload: res.data,
    })
  } catch (error) {
    console.log(error)
  }
}
export const getNoveltiesAction =
  (env, full = false) =>
  async (dispatch, getState) => {
    if (getState().novelties.loaded_novelties || !env?.APISERVER) {
      return null
    }
    dispatch({ type: LOADING_NOVELTIES })
    try {
      const res = await axios.get(
        `${env.APISERVER}/blog/novedades/get_data_summary/?client=26`
      )
      res.data.news = orderNovelties(res.data.news)
      dispatch({
        type: GET_GRID_NOVELTIES,
        payload: res.data.news,
      })
      if (getState().novelties.tags.length === 0) {
        dispatch({
          type: GET_TAGS,
          payload: res.data.tags,
        })
      }
      if (full) {
        dispatch({
          type: GET_NOVELTIES,
          payload: res.data.news,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
export const fillNoveltiesAction = novelties => async (dispatch, getState) => {
  dispatch({
    type: GET_GRID_NOVELTIES,
    payload: novelties,
  })
}
export const getTagsAction = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_TAGS })
  try {
    const res = await axios.get(
      "https://dev.mediacore.com.ar/api/v1/tags/search?with_posts=26&clients=" +
        getState().settings.client_id,
      {
        headers: {
          Authorization: "Bearer " + getState().settings.keys.mediacore,
        },
      }
    )
    dispatch({
      type: GET_TAGS,
      payload: res.data.data.tags.data.filter(element =>
        element.categories.find(category => category.name === "blog")
      ),
    })
  } catch (error) {
    console.log(error)
  }
}
export const fillTagsAction = tags => async (dispatch, getState) => {
  dispatch({
    type: GET_TAGS,
    payload: tags,
  })
}
export const getFiltersNoveltiesAction = env => async (dispatch, getState) => {
  dispatch({ type: CLEAN_GRID_NOVELTIES, action: [] })
  dispatch({ type: LOADING_GRID })
  dispatch({ type: LOADING_NOVELTIES })

  let filters = []
  getState().novelties.filtersNovelties.map(filter => {
    filters.push(filter)
  })
  let params = ""
  if (filters.length > 0) {
    params = "&tags=" + filters.join(",")
  }
  try {
    const res = await axios.get(
      `${env.APISERVER}/blog/novedades/get_data/?client=26${params}`
    )
    dispatch({
      type: GET_GRID_NOVELTIES,
      payload: orderNovelties(res.data.news),
    })
  } catch (error) {
    console.log(error)
  }
}
export const updateFiltersNovelties = tags => (dispatch, getState) => {
  dispatch({ type: UPDATE_FILTERS, payload: tags })
}
export const clearNoveltyAction = () => async (dispatch, getState) => {
  dispatch({ type: CLEAR_NOVELTY })
}
export const clearNoveltiesAction = () => async (dispatch, getState) => {
  dispatch({ type: CLEAN_GRID_NOVELTIES })
}
export const clearFiltersAction = () => async (dispatch, getState) => {
  dispatch({ type: UPDATE_FILTERS, payload: [] })
}
