export const getTextShort = (text,length) => {
    return text?.length > length ? text.substr(0,length) + '...' : text
}

export const getDateTime = (date,full = false) => {
    const Month = {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre'
    }
    if(date){
        const dateTime = date.split(':')
        let times = dateTime[0].split(' ')
        times=times[0].split("-")
        return full ? [times[2].split("T")[0]] + " de " + Month[parseInt(times[1])]+ ' de ' + times[0] : Month[parseInt(times[1])] + " " + times[0]
    }
    return 'Enero 2020'
}

export const getFeatured = (novelties) => {
    if(novelties){
      return novelties.filter(item => item.featured === 'true' || item.featured === '1' || item.featured === true)
    }
}

export const getNotFeatured = (novelties) => {
    if(novelties){
      return novelties.filter(item => item.featured === 'false' || item.featured === '0' || item.featured === false)
    }
}

export const getThree = (novelties) => {
    if(novelties){
        return novelties?.slice(0,3)
    }
    return []
}

export const orderNovelties = (novelties) => {
    const noveltiesPair = novelties;
    for(let i = 0; i < noveltiesPair.length; i++){
        const date = noveltiesPair[i].date_published.split("T")[0].split("-")
        noveltiesPair[i].parseDate = new Date(date[0], date[1]-1, date[2])
        console.log(noveltiesPair[i].parseDate)
    }
    return(noveltiesPair.sort((a, b) => b.parseDate.getTime() - a.parseDate.getTime()))
}

export const getNoveltiesCircular = (novelties,onlyCircular = false) => {
    if(novelties){
        const search = novelties.filter(novelty => novelty.tags.find(tag => tag.name.toLowerCase() === 'circular'))
        return search
    }
    return []
}

export const getNovelties = (novelties,onlyCircular = false) => {
    if(novelties){
        return novelties?.filter(novelty => !novelty.tags.find(tag => tag.name.toLowerCase() === 'circular'))
    }
    return []
}

// Avoid show duplicated novelties
export const avoidDuplicated = (actualNovelty, allNovelties) => {
    return allNovelties?.filter(n =>  actualNovelty?.id !== n?.id)
}

// First newest novelty, ordered by date_published prop
export const orderbyDate = (novelties) => {
    return (novelties && novelties?.length > 0)
        ? novelties?.sort((n1,n2) => new Date(n2?.date_published) - new Date(n1?.date_published))
        : null
}